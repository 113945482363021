import GATSBY_COMPILED_MDX from "/home/omar/projects/matterandvoid.space/personal-site-gatsby/src/posts/2013-12-04-thirty-second-shower/index.mdx";
import React from 'react';
import {Link, graphql} from 'gatsby';
import {MDXProvider} from '@mdx-js/react';
import Bio from '../components/Bio';
import BlogTitle from '../components/blogTitle';
import Layout from '../components/Layout';
import Pill from '../components/pill';
import Highlight from '../components/highlight-code';
import {rhythm, scale} from '../utils/typography';
const mdxComponents = {
  Highlight
};
function BlogPostTemplate({data, children, location, pageContext: {sectionName, subtitle}, ...props}) {
  console.log('data: ', data);
  console.log('props: ', props);
  const siteTitle = data.site.siteMetadata.title;
  const post = data.mdx;
  const {previous, next} = data;
  return React.createElement(Layout, {
    location: location,
    title: React.createElement(BlogTitle, {
      title: siteTitle,
      subtitle: sectionName
    }),
    subtitle: subtitle
  }, React.createElement("h1", null, post.frontmatter.title), React.createElement("div", {
    style: {
      ...scale(-1 / 5),
      marginBottom: rhythm(1),
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement("span", {
    style: {
      marginRight: '2rem'
    }
  }, post.frontmatter.date), React.createElement(Pill, {
    text: post.frontmatter.type
  })), React.createElement(MDXProvider, {
    components: mdxComponents
  }, children, " "), React.createElement(Bio), React.createElement("ul", {
    style: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      listStyle: 'none',
      padding: 0
    }
  }, React.createElement("li", null, previous && React.createElement(Link, {
    to: previous.fields.slug,
    rel: "prev"
  }, " ← ", previous.frontmatter.title)), React.createElement("li", null, next && React.createElement(Link, {
    to: next.fields.slug,
    rel: "next"
  }, next.frontmatter.title, " →"))));
}
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query PageTemplate($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt
      frontmatter {
        title
        date
        type
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
